import (
	/* webpackChunkName: "ui" */
	/* webpackMode: "lazy" */
	'./ui.js').then(ui => ui.init());

if (process.env.NODE_ENV === 'production') {
	// import (
	// 	/* webpackChunkName: "sw-uninstall" */
	// 	/* webpackMode: "lazy" */
	// 	'./service-worker.js');

	import (
		/* webpackChunkName: "ga" */
		/* webpackMode: "lazy" */
		'./analytics.js').then(ga => ga.init());
}

// if (document.getElementById('cse') != null) {
// 	import (
// 		/* webpackChunkName: "cse" */
// 		/* webpackMode: "lazy" */
// 		'./search.js').then(cse => cse.init());
// }

// // Função para voltar à página anterior e mostrar o link corretamente quando o usuário passa o mouse em cima.
// // Utilizado em 404.html
// var element = document.getElementById('back-link');
// // Provide a standard href to facilitate standard browser features such as 
// //  - Hover to see link
// //  - Right click and copy link
// //  - Right click and open in new tab
// element.setAttribute('href', document.referrer);
// // We can't let the browser use the above href for navigation. If it does, 
// // the browser will think that it is a regular link, and place the current 
// // page on the browser history, so that if the user clicks "back" again,
// // it'll actually return to this page. We need to perform a native back to
// // integrate properly into the browser's history behavior
// element.onclick = function() {
//   history.back();
//   return false;
// }

